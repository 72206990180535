import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hazardousSituation", "controlForm"];
  connect() {
    this.updateProbabilityHint();
    this.updateHazardousSituationHint();
    this.updateControl();
  }

  updateProbabilityHint() {
    ["p1", "p2", "controlled_p1", "controlled_p2"].forEach(function (key) {
      const value = document.querySelector(`input[name="risk_risk[${key}]"]:checked`)?.value;
      if (!value) return;
      const hint = document.querySelector(`.risk_risk_${key} .form-text`);
      hint.innerHTML = document.querySelector(`[data-probability="${value}"]`).innerHTML;
    });
  }

  updateHazardousSituationHint() {
    const hazardousSituation = this.hazardousSituationTarget;
    const hint = hazardousSituation.parentElement.querySelector(".form-text");

    hint.innerHTML = document.querySelector(`[data-hazard="${hazardousSituation.value}"]`).innerHTML;
  }

  updateControl() {
    const control = document.querySelector('input[name="risk_risk[control]"]:checked').value;
    this.controlFormTarget.style.display = control == "true" ? "block" : "none";
  }
}
